body {
  background-color: #011021 !important;
}
.login_bg {
  background-image: url(../images/login_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 95.2vh;
}

.bg-home {
  background-image: url(../images/background.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.h-screen-fit {
  height: 95.2vh;
}

.container-bg {
  background-color: #011021;
}

.apexcharts-canvas {
  background-image: url('../images/chart-bg.svg');
}

.ant-layout-header {
  background: #011021;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}

.ant-layout-content {
  padding-top: 64px;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
button:disabled {
  background: #4F4F4F;
  border-color: #4F4F4F;
}
.full-tabs {
  padding: 10px 5px;
  white-space: nowrap;
  overflow-x: auto;
}
.full-tabs > div {
  background: #fff;
  border: 1px solid #eee;
  color: #0999c3;
  font-size: 16px;
  display: inline-block;
  margin-right: 5px;
  padding: 3px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.full-tabs > div.active {
  background: #0999c3;
  color: #fff;
}